@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

.accordion_section {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.accordion {
  justify-content: space-between;
  background: transparent;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion_title {
  font-weight: 600;
  font-size: 2.5vh;
  text-align: left;
  font-family: Source Sans Pro;
}

.accordion_icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}
.rotate1 {
  transform: rotate(-90deg);
}

.accordion_content {
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion_text {
  font-weight: 400;
  font-size: 1.8vh;
  color: rgba(255, 255, 255, 0.7);
}
.accordion_list {
  margin: 0vh 0vh 2.4vh 1.4vh;
  padding-left: 2vh;
  text-align: left;
}
button {
  height: 7.2vh;
}
