@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: '4vh';
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

#container {
  width: 20vh;
  height: 20vh;
}

@-webkit-keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-name: animation;
          animation-name: animation;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier;
          animation-timing-function: cubic-bezier;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.accordion_section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  pointer-events: auto;
}

.accordion {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: transparent;
  cursor: pointer;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion_title {
  font-weight: 600;
  font-size: 2.5vh;
  text-align: left;
  font-family: Source Sans Pro;
}

.accordion_icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rotate1 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.accordion_content {
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion_text {
  font-weight: 400;
  font-size: 1.8vh;
  color: rgba(255, 255, 255, 0.7);
}
.accordion_list {
  margin: 0vh 0vh 2.4vh 1.4vh;
  padding-left: 2vh;
  text-align: left;
}
button {
  height: 7.2vh;
}

.button {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  padding: 1vh;
  color: #f2f2f2;
  margin: 3px;
  /* border-radius: 5px; */
  outline: none;
  /* pointer-events: auto; */
  cursor: pointer;
  list-style-type: none;
}

.button.toggled {
  background: rgba(255, 255, 255, 0.2);
  /* color: #97bf55; */
  color: #f2f2f2;
  font-size: 2vh;
  cursor: pointer;
}
.materialList {
  background: rgba(255, 255, 255, 0.2);
  padding: 2vh 0vh 2vh 0vh;
  margin: 0vh;
}

