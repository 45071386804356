@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;400;500;700&display=swap');
.button {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  padding: 1vh;
  color: #f2f2f2;
  margin: 3px;
  /* border-radius: 5px; */
  outline: none;
  /* pointer-events: auto; */
  cursor: pointer;
  list-style-type: none;
}

.button.toggled {
  background: rgba(255, 255, 255, 0.2);
  /* color: #97bf55; */
  color: #f2f2f2;
  font-size: 2vh;
  cursor: pointer;
}
.materialList {
  background: rgba(255, 255, 255, 0.2);
  padding: 2vh 0vh 2vh 0vh;
  margin: 0vh;
}
